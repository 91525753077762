<template>
  <div>
    <all-notifications/>

    <router-view/>
  </div>
</template>

<script>
import AllNotifications from "@/components/all-notifications/AllNotifications";

export default {
  name: "FullPage",
  components: {AllNotifications}
}
</script>
